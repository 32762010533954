.summary-item {
  width: 90%;
  margin: auto;
  margin-bottom: 30px;
  margin-block-start: 10px;
  max-height: inherit;
  text-align: left;
  .header-toggle {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    cursor: pointer;
    .header-info {
      margin-left: 10px;
    }
    .header-icon {
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }
  }

  .table-container {
    border-top: 1px solid black;
    padding-top: 10px;
    table {
      width: 100%;
      td {
        width: 50%;
        padding: 2px 10px;
      }
      td:nth-child(2) {
        background-color: #f1f7f3;
        width: 30%;
        padding-left: 1em;
      }
    }
  }
}

.plus-minus-word {
  text-align: left;
  font-size: large;
  font-weight: 700;
  padding-left: 0.2em;
  display: inline-block;
}

.ul-style {
  list-style: disc;
  text-align: left;
  margin-left: 1.5em;
}

.li-style {
  padding-bottom: 1em;
}

.plus-minus-l2 {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

.plus-minus-l3 {
  float: left;
  width: 17em;
}

.td-text {
  vertical-align: middle;
  padding-bottom: 6px;
}

.display.nice {
  display: inline-block;
}

.h1-tag {
  font-size: 2em;
  font-weight: 500;
  padding-bottom: 0.5em;
}

.percentage-feedback {
  display: inline;
  vertical-align: middle;
  font-size: 3em;
  font-weight: 500;
  padding-left: 1em;
}

.text-after-percent {
  display: inline;
  font-size: 1.5em;
  padding-left: 1em;
}

.all-radius {
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
}

.imageBox-articleAndTest {
  width: 20em;
}
.img-articleAndTest {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.h1-standard-text {
  font-size: 1.2em;
}

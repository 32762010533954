.main-intro {
  text-align: center;
  img {
    width: 100px;
    height: auto;
    min-height: 40px;
  }
}

.onboarding-box {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  //box-sizing: border-box;
  margin-top: 10px;
  border-radius: 25px;
  position: relative;
  margin-bottom: 5px;
  color: white;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.zoom-special-button:hover {
  transform: scale(1.02);
}

.white-container {
  background: white;
  border-radius: 15px;
  padding: 20px;
  color: black;
  text-align: center;
  box-shadow: 0px 4px #003a79;
}

.filter-tag,
.label-tag {
  margin: 0.2em 0.2em 0.2em;
  display: inline-block;
}

.filter-tag-icon {
  font-size: 120% !important;
}

.filter-tag-fab {
  border-radius: 8px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-size: 80% !important;
  height: 30px !important;
}

.filter-tag-fab.active {
  box-shadow: 0px 2px #0096b6;
}

.filter-tag-fab.inactive {
  box-shadow: 0px 2px grey;
}

.search-field {
  margin-bottom: 2em;
  display: inline-block;
}

.search-field .text-field-special input {
  margin-left: 10px;
}

.pc {
  display: none;
  visibility: hidden;
}

.mobile {
  display: block;
  visibility: visible;
}

@media screen and (min-width: 992px) {
  .onboarding-box {
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: #003a79;
    padding: 15px;
    //box-sizing: border-box;
    font-weight: 300;
    text-align: left;
  }

  .pc {
    display: inherit;
    visibility: visible;
  }

  .mobile {
    display: none !important;
    visibility: hidden !important;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .onboarding-box {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
  }
}

.app {
  text-align: center;
}

a {
  text-decoration: none;
}

a,
a:visited {
  color: #000;
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.flex-content {
  display: flex;
  //box-sizing: border-box;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  .box {
    background-color: #f1f7f3;
    width: 30%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0px 4px #003a79;
    transition: 1s all;
    transition: 1s height;
    margin-top: 3.5px;
    margin-left: 3.5px;
    margin-right: 3.5px;
    margin-bottom: 3.5px !important;
  }
}

.all-radius {
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
}

.boxicons {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.spacer {
  flex-grow: 1;
}

.login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 100px;
  .form-container {
    padding: 40px 80px;
    background-color: white;
  }
  p,
  button {
    margin-top: 15px;
    font-family: 'Roboto', sans-serif;
  }
}

@media screen and (min-width: 992px) {
  .super-container {
    min-height: auto;
    padding-top: 220px;
    .main-container {
      // width: 1000px;
      height: auto;
      min-height: auto;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .flex-content {
    display: flex;
    //box-sizing: border-box;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    .box {
      background-color: #f1f7f3;
      width: 30%;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      box-shadow: 0px 4px #003a79;
      transition: 1s all;
      transition: 1s height;
      margin-top: 7px;
      margin-left: 7px;
      margin-right: 7px;
      margin-bottom: 7px !important;
    }
  }
}
.boxWithAnimation-enter {
  opacity: 0;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}
.boxWithAnimation-enter-active {
  width: 95% !important;
  opacity: 1;
  transition: opacity 300ms linear;
  margin-left: auto;
  margin-right: auto;
}
.boxWithAnimation-enter-done {
  width: 95% !important;
  margin-left: auto;
  margin-right: auto;
}

.boxWithAnimation-exit {
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}

.boxWithAnimation-exit-active {
  width: 95% !important;
  opacity: 0;
  transition: opacity 300ms linear;
  margin-left: auto;
  margin-right: auto;
}

$width: 140px;
$height: 100px;
$border-r: 5px;
$icon-size: 58px;

#filterbox {
  width: $width;
  height: $height;
}

.zoom-special:hover {
  transform: scale(1.05);
}

.chosen-arrow-down-fb {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin-left: 25px;
  margin-top: 0px;
  border-top: 15px solid whitesmoke;
}

.boxtitle {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  color: #003a79;
  font-weight: 500;
  text-align: center;
  padding: 5px;
  font-size: 0.9em;
  margin-top: auto;
}

.box-table {
  width: #{$width}; //calc(#{$width} - (#{$border-r} * 2));
  height: #{$height}; //calc(#{$height} - (#{$border-r} * 2));
  //margin-top: 5px;
}

.box-icon-cell {
  width: 100%;
  height: 90%;
  padding-top: 7px;
  padding-left: 5px;
}

.box-icon-cell-add {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0em;
}

.box-icon {
  display: block;
  /* margin-left: calc((#{$width} - (#{$border-r} * 2) - #{$icon-size}) / 2);
  height: calc(#{$height} - (#{$border-r} * 2) - 2em); */
  margin-left: 43px;
  margin-top: 10px;
  height: 45px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.box-icon-add {
  display: block;
  height: calc(#{$height} - (#{$border-r} * 2) - 3.2em);
}

.box-title {
  color: #003a79;
  font-weight: 500;
  font-size: 1em;
  text-align: center;
  height: 1.5em;
}

.box-cross-cell {
  width: 10px;
  text-align: right;
  vertical-align: top;
  z-index: 1;
  padding-top: 5px;
  padding-right: 5px;
}

.box-cross {
  color: grey;
}

.box-cross:hover {
  color: indianred;
}

.submit-button-container {
  width: 100% !important;
  padding: 10px 0 0 0;
  box-sizing: border-box;

  bottom: 0px;
  left: 0px;
  z-index: 5;
  .submit-button {
    color: #003a79;
    cursor: pointer;
    width: 95%;
    background-color: #a1d2b5;
    box-shadow: 0 4px #003a79;
    border: 0px;
    height: 50px;
    font-size: 1em;
    font-weight: bold;
    //box-sizing: border-box;
    padding: 0px;
    padding-top: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
  }
}

.submit-button-container-animation{
  animation: flip-in-hor-bottom 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  
  @keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
      transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      opacity: 1;
    }
  }
}

@media screen and (min-width: 992px) {
  .submit-button-container {
    width: 100% !important;

    display: flex;
    justify-content: center;
    background-color: transparent;
    margin-right: 2px;
    .submit-button {
      //   transform: scale(0);
      -webkit-animation-name: buttonboop;
      -webkit-animation-duration: 2s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-fill-mode: forwards;
      background-color: #a1d2b5;
      box-shadow: 0 4px #003a79;
      width: 400px;
      text-align: center;
    }
  }
}

$main-dark-blue: rgba(0, 76, 156, 0.91);

.main-container {
  min-height: 120vh;
  font-family: 'Roboto', sans-serif;
  background-color: $main-dark-blue;
  padding-bottom: 70px;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 350px) {
  .main-container {
    min-height: 120vh;
    font-family: 'Roboto', sans-serif;
    background-color: $main-dark-blue;
    padding-bottom: 70px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 800px) {
  .main-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.main-header {
  display: inherit;
  color: #fff;
  width: 100%;
  height: auto;
  padding: 20px 0;
  text-align: center;
}

.main-logo {
  height: 50px;
  cursor: pointer;
}

.main-logotext {
  margin: auto;
  font-family: 'Roboto', sans-serif;
  color: white;
  font-weight: 400;
  h1 {
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
    font-size: 3em;
    font-weight: 300;
  }
}

.pc {
  display: none;
  visibility: hidden;
}

.mobile {
  display: block;
  visibility: visible;
}

@media screen and (min-width: 992px) {
  .main-container {
    padding: 20px 50px;
    border-radius: 25px;
    background-color: $main-dark-blue;
  }

  .main-header {
    position: relative;
    display: inline-block;
    background-color: transparent;
    padding: 0px;
    box-shadow: none;
    margin-bottom: 60px;
    left: 0px;
    text-align: left;

    img {
      position: absolute;
      left: 0px;
      top: -70px;
      height: 70px;
      width: auto;
      object-fit: contain;
    }

    h1 {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      text-align: left;
      color: white;
      margin-top: 30px;
      font-size: 4em;
    }
  }
  .pc {
    display: inherit;
    visibility: visible;
  }

  .mobile {
    display: none !important;
    visibility: hidden !important;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 340px;
  background-color: #138036;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 10;
  top: 150%;
  left: 50%;
  margin-left: -166px;
  margin-top: -15px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #138036 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptextMobile {
  visibility: hidden;
  width: 320px;
  background-color: #138036;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: -13px;
  right: 125%;
}

.tooltip .tooltiptextMobile::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #138036;
}
.tooltip:hover .tooltiptextMobile {
  visibility: visible;
}

.expansion-panel {
  height: 3em;
  width: 100%;
  margin: 10px 0px;
  background-color: #b1e9f6;
  color: #004c9c;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 4px #003a79;
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  h2 {
    font-weight: 500;
    margin-top: 0.8em;
    margin-left: 15px;
    margin-right: 5px;
  }
  h3 {
    font-size: medium;
    align-self: center;
    font-weight: 300;
  }
  
  .expand-icon {
    position: absolute;
    right: 7px;
    top: 12px;
  }
}
.expansion-panel-content {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .expansion-panel {
    box-shadow: 0px 4px #003a79;
    background-color: #b1e9f6;
    color: #004c9c;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
}

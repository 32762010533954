.child-thead{
    display: flex;
    width: 100%;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    margin-bottom: 7px;
}

.row-main{
    display: flex;
    flex: 1;
    font-size: 0.8em;

    
    line-height: 2;

    /* display: flex;
    width: 150%;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    margin-bottom: 15px;
    right: 27px; */
}

.row-child-1{
    width: 50%;
    text-align: left;
}

.row-child-2{
    width: 50%;
    text-align: right;
    background-color: white !important;
}

.row-data{
    text-align: right;
}

.td-car {
    font-size: 0.85em !important;
    color: #003a79 !important;
    //padding: 0px !important;
    //border-bottom: 0 !important;
    
}

.td-car:hover {
    cursor: pointer;
    font-weight: 500 !important;
  }


.table-view table {
    font-weight: 300;
    display: flex;
    justify-content: left;
    color: black;
    flex-direction: column;
    min-width: 100px;
    //width: 97%
    padding-left: 20px;
    padding-right: 20px;
}

.child-tbody {
    width: 100% !important;
    border-bottom: 1px solid lightgray;

    padding-bottom: 10px;
    border-collapse: separate;

    /*
    width: inherit;
    margin: 0px;
    display: inline-table;
    justify-content: center;
    */
}

.MuiTableCell-root {
    padding: 0px !important;
}

.MuiInput-underline:before {
    border-bottom: none !important
    
}

.MuiInput-root {
    position: relative;
    height: 1rem;
}

[aria-label="Previous page"]{
    right: 185px;
}

.child-pagination .MuiSvgIcon-root {
    font-size: 2.5rem;
    
}

.child-pagination .MuiTablePagination-actions {
        
    margin-left: 0px;
}

.child-pagination .MuiIconButton-root:hover {
    background-clip: content-box;
}

.child-pagination .MuiIconButton-root {
    padding: 0px 12px;
}
.all-radius-gallery-original {
  img {
    border-radius: 15px;
  }
}

.all-radius-gallery-thumbnail {
  border-radius: 8%;
}

.image-gallery-thumbnail-image {
  width: 160px;
  object-fit: cover;
  border-radius: 8%;
  height: 50px;
}
.image-gallery-container {
  max-width: 100vw;
}

.image-gallery-container-mobile {
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 225px !important;
  }
}

.image-gallery {
  max-width: 100vw !important;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  border-radius: 15px !important;
}

// Max-height with Object-fit for a picture so that it fits into the container.
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 600px !important;
  width: 100%;
  object-fit: cover;
  height: max-content;
}

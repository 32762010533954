@media screen and (min-width: 992px) {
  .allradius {
    border-radius: 15px;
  }
}

.column {
  flex-direction: column;
  justify-content: flex-start;
}
.contentimgdiv {
  padding: 10px 20px;
  background-color: white;
  margin: auto;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  max-height: inherit;
  max-width: 100vw;
  flex-wrap: wrap;
}

.details-list-element {
  .tableContainer {
    width: 100%;
    table {
      display: table;
      border-collapse: separate;
      border-spacing: 2px;
      border-color: grey;
      width: 100%;
      table-layout: fixed;
      tr {
        width: 100%;
      }
      td {
        text-align: right;
        margin-top: 2px;
        font-weight: 400;
        padding-right: 1em;
        width: 50%;
      }

      td:nth-child(even) {
        text-align: left;
        background-color: #f1f7f3;
        min-width: 100px;
        padding: 0.3em;
        padding-left: 1em;
        width: 50%;
      }
      tr:hover .tableContent {
        background-color: #a2d2b6;
      }
    }
  }

  h2 .tableContainer td {
    font-family: Roboto, sans-serif;
  }

  h2 {
    font-weight: 700;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }
}

@media screen and (min-width: 800px) {
  .details-section {
    max-width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 601px) {
  .tableContainer {
    td {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 600px) {
  .tableContainer {
    td {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 480px) {
  .details-list-element {
    h2 {
      font-size: 24px;
    }
    .tableContainer {
      td {
        text-align: left;
        margin-top: 2px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .details-list-element {
    .column {
      align-items: flex-start;
    }
    h2 {
      font-size: 16px;
      margin: 10px 0;
    }
    .tableContainer {
      td {
        font-size: 12px;
      }
    }
  }
  .disclaimerText {
    font-size: 12px;
  }
}

@media screen and (max-width: 350px) {
  .details-list-element {
    .table {
      .column {
        align-items: flex-start;
      }
      .tableContainer {
        td {
          font-size: 10px;
          overflow: auto;
        }
        td:nth-child(even) {
          width: 30%;
        }
      }
    }
    .disclaimerText {
      font-size: 10px;
    }
  }
}

.box {
  cursor: pointer;
}

$width: 140px;
$height: 100px;
$border-r: 5px;
$icon-size: 58px;

.zoom-special:hover {
  transform: scale(1.05);
}

.chosen-arrow-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin-left: 20px;
  margin-top: 0px;
  border-top: 15px solid whitesmoke;
}

.bolder-text {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  color: #ec6723;
  font-weight: 600;
  text-align: center;
  padding: 5px;
  font-size: 1.05em;
  margin-top: auto;
}

.box-icon-result {
  display: block;
  height: calc(#{$height} - (#{$border-r} * 2) - 2em);
  margin-top: 0.3em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.boxtitle-result {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  color: #003a79;
  font-weight: 500;
  text-align: center;
  padding: 5px;
  font-size: 1.05em;
  margin-top: auto;
}

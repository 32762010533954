.car-thumbnail-box {
  display: inline-block;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 4px #e9e9e9;
  width: 100%;
  max-height: 500px;
  max-width: 300px;

  .tooltip {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    margin-left: -30px;
    left: -80px;
    bottom: -2px;

    //For Mobile List-View
    .tooltiptextMobile::after {
      top: 20%;
    }
    
    .tooltiptextMobile {
      
      width: 150px;
      z-index: 10;
    }

    .tooltiptext {
      margin-top: -9px;
    }
  }

  .header {
    text-align: center;
    padding: 10px 0;
    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
    h1 {
      font-size: 37px;
    }
  }
  table {
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0 5px;
    td:last-child {
      text-align: right;
    }
  }
}

.thumbnail-list-style {
  table {
    width: 100%;
    text-align: left;
    color: black;
    td {
      font-weight: 400;
    }
    td:nth-child(2) {
      background-color: #f1f7f3;
      text-align: right;
    }
    td.highlighted {
      background-color: #ec6723;
      color: #fff;
    }
  }
}

.thumbnail-list-style-square {
  
  table {
    text-align: left;
    color: black;
    td {
      font-weight: 400;
    }
    td:nth-child(2) {
      background-color: #f1f7f3;
    }
    td.highlighted {
      background-color: #ec6723;
      color: #fff;
      text-align: center;
      border-radius: 15px;
    }
  }
}

@media screen and (min-width: 992px) {
  .car-thumbnail-box {
    display: inline-block;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 4px #e9e9e9;
    width: 100%;
    .header {
      text-align: center;
      padding: 10px 0;
      img {
        width: 100%;
        height: 150px;
        object-fit: contain;
      }
    }
  }

  .thumbnail-list-style td.highlighted {
    padding-right: 10px;
    border-radius: 10px;
    margin-right: -20px;
  }
}

//streamView
.darkbluebackground {
  box-shadow: 0 4px #003a79;
  max-height: 500px;
  max-width: 300px;
  min-height: 343px;
  min-width: 310px;
  background-color: #003a79;
  background-size: 100%;
  padding-top: 3em;
  border-radius: 15px;
  background-image: url(../../../assets/cars-pictures/testbgresult.png);
  background-position: 0 25px;
  background-repeat: no-repeat;
  font-size: large;
}

.whitebackground {
  border-radius: 15px;
  background-position: center;
  position: relative;
  transition: all 2s;
  background-color: #f1f7f3;
  padding-bottom: 1em;
}
.img-car {
  width: 100%;
  height: 150px;
  object-fit: contain;
  z-index: 4;
  position: relative;
  outline: none;
}
.img-hover:hover {
  transform: scale(1.17);
}

@media screen and (max-width: 350px) {
  .darkbluebackground {
    max-height: 300px;
    min-width: 0;
  }
  .img-car {
    width: 85%;
    padding-left: 20px;
  }
}
.h3-car {
  margin-bottom: 1em;
  z-index: 4;
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 1em;
  color: #003a79;
  overflow: hidden;
}
.table-view table {
  font-weight: 300;
  display: flex;
  justify-content: center;
  color: black;

  tbody {
    width: 230px;
    margin: auto;
    display: inline-table;
    justify-content: center;
  }

  .tooltip {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    margin-left: -20px;
    top: 92px;

    //For Mobile Stream-View
    .tooltiptextMobile::after {
      top: 15%;
    }
    
    .tooltiptextMobile {
      
      width: 150px;
      z-index: 10;
    }
  } 
}

.table-view-white-detailed {
  padding: 0 10px;
}

//columnView
.darkbluebackground-cv {
  box-shadow: 0 4px #003a79;
  height: 220px;
  width: 300px;
  background-color: #003a79;
  background-size: 100%;
  padding-top: 3em;
  padding-bottom: 0em;
  border-radius: 15px;
  background-image: url(../../../assets/cars-pictures/testbgresult.png);
  background-position: 0 25px;
  background-repeat: no-repeat;
  font-size: large;
  transition: 1s ease-in-out;
}

.whitebackground-cv {
  border-radius: 15px;
  background-position: center;
  position: relative;
  background-color: #f1f7f3;
}
.img-car-cv {
  width: 100%;
  height: 140px;
  object-fit: contain;
  z-index: 4;
  position: relative;
  top: 0;
  outline: none;
  transition: all 1s ease-in-out;
  transform-origin: bottom;
}
@media screen and (max-width: 350px) {
  .darkbluebackground-cv {
    height: 68vw;
    width: 85vw;
  }
  .img-car-cv {
    width: 90%;
    padding-left: 15px;
  }
}
.h3-car-cv {
  z-index: 4;
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 1em;
  height: 45px;
  color: #003a79;
  overflow: hidden;
  transition: height 1s ease-in-out;
}

.table-view-cv {
  position: relative;
}
.table-view-cv table {
  z-index: 5;
  position: absolute;
  top: 0;
  font-weight: 200;
  font-size: smaller;
  display: flex;
  justify-content: center;
  margin-top: 0.15em;
  transition: all 1s ease-in-out;
  overflow: hidden;
  max-height: 0px;
  color: black;
  background-color: #f1f7f3;
  width: 100%;
  tbody {
    width: 200px;
    margin: auto;
    display: inline-table;
    justify-content: center;
  }
  .tooltip {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    top: 38px;
    right: 50px;

    //Mobile for ColumnView
    .tooltiptextMobile::after {
      top: 11%;
    }
    
    .tooltiptextMobile {
      
      width: 150px;
      top: 0px;
      z-index: 10;
    }
}
}

.darkbluebackground-cv:hover {
  background-position: 0 -2em;
}

.darkbluebackground-cv:hover {
  .table-view-cv table,
  .img-car-cv {
    top: -30px;
  }

  .table-view-cv table {
    max-height: 80px;
  }
  .table-view-cv table:hover {
    overflow: visible;
  }

  .img-car-cv {
    transform: scale(1.2);
  }

  .h3-car-cv {
    height: 0;
  }
}

.flip-round-icon {
  font-size: 25px; 
  color: #00bce4; 
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer
}

.child-animation {
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.parent-animation {
  animation: flip-in-hor-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

@keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

.table-view-white-detailed-child {
  
  .child-thead{
    width: 116%;
  }
  
}

.table-view-white-detailed-child table {
  width: 90%
} 

.table-view-white-detailed-child [aria-label="Previous page"] {
  right: 162px;
}
.car-result-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.customize-section {
  margin-bottom: 20px;
  align-items: center;
}

.thumbnail-style-buttons {
  text-align: center;
}

.hover-icons :hover {
  color: #ec6723;
  transform: scale(1.02);
}

.show-expandable,
.show-two-wide {
  flex-basis: 200px;
  margin: 20px 10px;
}

.show-two-wide {
  flex-grow: 1;
}

.search-selector {
  margin-bottom: 20px;
  > * {
    margin: 10px 0;
  }
}

.iconContainer {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.no-results {
  img {
    max-width: 200px;
    margin-bottom: 20px;
    color: #fff;
  }
  text-align: center;
  margin: 100px auto;
  color: #fff;
}

@media screen and (min-width: 480px) {
  .infotextformobile {
    display: none;
  }
  .search-selector {
    .formcontrol {
      min-width: 220px;
    }
  }
}

@media screen and (max-width: 480px) {
  .infotextformobile {
    display: initial;
  }
  .search-selector,
  .search-selector > * {
    width: 100%;
  }
  .no-results {
    margin-top: 50px;
    img {
      max-width: 100px;
    }
  }
}

@media screen and (min-width: 992px) {
  .iconContainer {
    max-width: 900px;
    margin: 0 auto;
  }
}

.filterExpantionContent {
  width: 100%;
}

@media (max-width: 480px) {
  .viewMobile {
    width: 95% !important;
  }
}

.resetFilters {
  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
}

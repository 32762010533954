/* Removes the number input's spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.info-icon {
  width: 1.5em;
  height: 1.5em;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 5px;
  color: #003a78;
}

.zoom-info-icon:hover {
  transform: scale(1.35);
  color: #004c9c;
}

.checkbox-container-filter,
.checkBox-filter,
.radio-container-filter,
.radio-filter {
  display: flex;
  justify-content: space-evenly;
}
.helpText-center {
  margin-left: auto !important;
  margin-right: auto !important;
}
.header-fix {
  font-size: 150%;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.1em;
  display: inline-block;
}
.header-fix-label {
  font-size: 150%;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.1em;
  padding-bottom: 0.7em;
  display: inline-block;
}

.inputIncludingInfo {
  margin-top: 1em;

  .paper {
    flex-basis: 10em;
  }
  .inputinfo-wrapper {
    margin: 0.5em;
    height: 1em;
    line-height: 1;

    .inputinfo {
      min-height: 1em;
      p {
        margin: auto 0;
      }
    }
  }
}

.master-container {
  display: flex;
  flex-direction: row;
}

.search-and-add,
.tabitem {
  margin-top: 15px;
}

.search-and-add > div {
  margin-right: 15px;
}

.carListContainer {
  padding: 15px;
  width: 340px;
  > div {
    margin-top: 15px;
    max-height: 80vh;
    overflow: auto;
  }
}

.carCategoriesFormContainer {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  .deleteButton {
    width: fit-content;
  }
}

.list-item {
  background-color: #fff;
  border-bottom: 1px #d8d8d8 solid;
}
.list-item.active {
  background-color: #cee8ff;
}
.list-item:hover {
  background-color: #cee8ff;
  cursor: pointer;
}

.input-field-container {
  margin: 10px 0;
}

.field-container {
  max-height: 68vh;
  overflow: auto;
  > div {
    margin-top: 20px;
    h2 {
      font-weight: 700;
    }
  }
}

.form-actions {
  margin-top: 10px;
}

.form-container {
  background-color: #fff;
  padding: 40px;
  margin-bottom: 15px;
  width: 50vw;
  max-height: 75vh;
  overflow: auto;
  float: left;
  form > div {
    .textfield,
    .selectfield {
      margin: 10px 0;
    }
    .MuiFormControl-root,
    .MuiFormControlLabel-root {
      min-width: 300px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .MuiTextField-root,
    .MuiInputLabel-root {
      width: 100%;
    }
    button {
      margin-top: 20px;
      // width: fit-content;
    }
  }
}

.mini-form-container {
  max-height: 64vh;
  overflow: auto;
}

.visible-textfield {
  background-color: #fff;
  border-radius: 4px;
}

.plusMinusContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.inline-radio.MuiFormGroup-root {
  flex-direction: row;
}

.inline-radio {
  .MuiFormControlLabel-root {
    min-width: unset !important;
  }
}

.positive {
  border: 5px solid #43a047;
  background-color: #c8e6c9;
  max-width: 450px;
}

.negative {
  border: 5px solid #e53935;
  background-color: #ffcdd2;
  max-width: 450px;
}

.light-green-shell {
  background-color: #f1f7f3;
  color: #003978;
  width: 98%;
  margin-left: 1%;
  padding: 10px;
  margin-block-start: 15px;
  margin-block-end: 15px;
  box-sizing: border-box;
  box-shadow: 0px 4px #003978;
  font-weight: 900;
  font-size: 1.1em;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .light-green-shell {
    background-color: #f1f7f3;
    box-shadow: 0px 4px #003a79;
    color: #003a79;
    margin-bottom: 20px;
    height: 48px;
    box-sizing: border-box;
    padding-top: 12px;
    font-size: 1.1em;
    font-weight: 500;
  }
}
